import NextLink, { type LinkProps as NextLinkProps } from 'next/link';
import type { ComponentProps, PropsWithChildren } from 'react';
import { styled } from '@styled-system/jsx';

const StyledNextLink = styled(NextLink, {});

type AProps = ComponentProps<typeof StyledNextLink>;

export type LinkProps = PropsWithChildren<NextLinkProps & AProps>;

export const Link = ({ href, className, children, ...props }: LinkProps) => {
  return (
    <StyledNextLink
      href={href}
      prefetch={false}
      {...props}
      className={className}
    >
      {children}
    </StyledNextLink>
  );
};
