// https://www.figma.com/file/SjYKsDIXB2uTv4wXURb4K3if/TELLER-DESIGN-GUIDELINE?node-id=1%3A27
export enum Color {
  ACCENT_1000 = '#313238',
  ACCENT_700 = '#62646e',
  ACCENT_500 = '#989ba1',
  ACCENT_100 = '#d7d8da',
  ACCENT_50 = '#edeeef',
  PRIMARY = '#fafafa',
  PRIMARY_TRANSPARENT = 'rgb(250, 250, 250, 0.8)',
  TINT = '#1b88ff',
  STRONG_BG = '#ebedf1',
  INACTIVE_TAB = '#a8aaaf',
  SERIES_BADGE_BG = '#666',
  FOOTER_SEPARATOR = '#575861',
  SEPARATOR = '#d7d8da',
  HIGHLIGHT = '#e9f0f7',
  WHITE = '#fff',
  BREADCRUMB_LIST_BG = '#575861',
  THUMBNAIL_BORDER = 'rgb(49, 50, 56, 0.3)',

  MAIN_BALLOON_BG = '#8CE02F',
  BALLOON_BG = 'rgba(255,255,255,0.9)',
  CHAT_BALLOON_BG = 'rgba(249,249,249,1)',

  NOTIFICATION_BG = 'rgba(255, 255, 255, 0.96)',

  ANSWER_CALL_BG = '#57dc77',

  YELLOW_BG = '#F9F5E8',

  VIP_MAIN_BG = '#f9f9f9',

  RED = '#f04a40',
  PINK = '#eb5757',
  GREEN = '#51ad93',
  GOLD = '#efb035',
  YELLOW = '#F2C94C',
  SILVER = '#9fb4bd',
  COPPER = '#be674c',
  GREY = '#313238',
  GREY_TRANSPARENT = 'rgb(49, 50, 56, 0.8)',
  GREY_600 = '#62646f',
  LINK = '#1d5084',
  TWITTER = '#1DA1F2',
  LINE = '#53B535',
}
