// Use em in media query to work properly cross-browser and support users
// https://zellwk.com/blog/media-query-units/
export enum Media {
  SMALL = '(max-width: 21em)', // = 320px / 16px (iPhone5)
  MEDIUM_SMALL = '(max-width: 35em)', // = 640px / 16px (iPhone5)
  MEDIUM = '(max-width: 60.4em)', // 970px
  LARGE_MEDIUM = '(min-width: 21em) and (max-width: 65.4em)',
  LARGE = '(min-width: 60.5em)', // = 968px / 16px
}

export const Breakpoints = {
  sm: '640px',
  md: '768px',
  lg: '1100px', // main(min: 760px) + aside(min: 300px) + margin(40px)
  xl: '1280px',
  '2xl': '1536px',
  '3xl': '2300px',
};
