export enum ZIndex {
  FOOTER = '100',
  BREADCRUMB = '101',
  GOOGLE_ADS = '104',
  PHONE_CALL = '110',
  SHARE_BUTTON = '103',
  HEADER = '104',
  SIDEBAR = '105',
  NOTIFICATION = '106',
  SEARCH_RESULTS = '107',
  OPEN_IN_APP = '104',
  GOD = '2147483647', // https://stackoverflow.com/questions/491052/minimum-and-maximum-value-of-z-index
}
