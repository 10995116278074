'use client';

import { Env } from '~/utils/environments';
/* eslint-disable import/no-default-export */
import { ErrorPageProps, ErrorContainer } from './_containers/error-container';

const BASE_URL = Env.baseURL;

export default function ErrorPage(props: ErrorPageProps) {
  return (
    <html lang="ja">
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `
  [
    {
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "${BASE_URL}",
      "logo": "${BASE_URL}logo512.png"
    },
    {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      "name": "テラーノベル：小説が読み放題！小説・夢小説を書くアプリ",
      "operatingSystem": "Android",
      "applicationCategory": "EntertainmentApplication",
      "downloadUrl": "https://play.google.com/store/apps/details?id=jp.picappinc.teller",
      "offers": {
        "@type": "Offer",
        "price": "0.00",
        "priceCurrency": "JPY"
      }
    },
    {
      "@context": "https://schema.org",
      "@type": "SoftwareApplication",
      "name": "テラーノベル：小説を読む・書く",
      "operatingSystem": "iOS",
      "applicationCategory": "EntertainmentApplication",
      "downloadUrl": "https://apps.apple.com/jp/app/id1238587832",
      "offers": {
        "@type": "Offer",
        "price": "0.00",
        "priceCurrency": "JPY"
      }
    }
  ]
  `,
          }}
        />
      </head>
      <body>
        <ErrorContainer {...props} />
      </body>
    </html>
  );
}
