import { routes } from '~/utils/routes';
import { Button, ButtonNextLink } from '@app/_components/button';
import { HeaderLogo } from '@app/_components/header';
import { css } from '@styled-system/css';
import {
  HStack,
  VStack,
  Image,
  styled,
  Center,
  Paragraph,
} from '@styled-system/jsx';
import TenoSurprise from './assets/teno-surprise-oops.webp';

const HEADER_PC_HEIGHT = 72;
const HEADER_SP_HEIGHT = 50;

export type ErrorPageProps = {
  error: Error & { digest?: string };
  reset: () => void;
};

export const ErrorContainer = ({ error, reset }: ErrorPageProps) => {
  return (
    <VStack w="100%" gap="0" alignItems="center">
      <header
        className={css({
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          bg: 'white',
          width: '100%',
          pl: {
            base: '12px',
            lg: '40px',
          },
          pr: {
            base: '12px',
            lg: '30px',
          },
          height: {
            base: `${HEADER_SP_HEIGHT}px`,
            lg: `${HEADER_PC_HEIGHT}px`,
          },
          borderBottom: '0.5px solid #D7D8DA',
        })}
      >
        <HStack
          justifyContent="space-between"
          maxW="1220px"
          margin={{
            base: '0',
            lg: 'auto',
          }}
          w="100%"
        >
          {/* Logo */}
          <HeaderLogo />
        </HStack>
      </header>
      <Center w="100%" h="100%">
        <VStack
          w="100%"
          p={{
            base: '16px',
            lg: '40px',
          }}
          gap="16px"
        >
          <styled.h2
            textStyle={{
              base: '2xl',
              lg: '4xl',
            }}
            textAlign="center"
            fontWeight="bold"
            color="accent.1000"
          >
            エラーが発生しました
          </styled.h2>
          <Image alt="not found" src={TenoSurprise} w="100%" maxW="240px" />

          <VStack
            gap="1"
            w="100%"
            maxW="3xl"
            mt="4"
            bg="white"
            p="4"
            borderRadius="lg"
          >
            <Paragraph
              color="accent.1000"
              textAlign="center"
              fontWeight="bold"
              fontSize="lg"
            >
              エラー内容
            </Paragraph>
            <Paragraph color="accent.1000" textAlign="center">
              {error.message}
            </Paragraph>
          </VStack>
          <VStack gap="4" w="100%" mt="4">
            <Button maxW="320px" onClick={() => reset()}>
              ページを再読み込み
            </Button>

            <ButtonNextLink
              maxW="320px"
              type="outline"
              href={routes.home.url()}
            >
              ホームに戻る
            </ButtonNextLink>
          </VStack>
        </VStack>
      </Center>
    </VStack>
  );
};
