import { Link } from '@app/_components/link';
import { cva, type RecipeVariantProps } from '@styled-system/css';
import { styled } from '@styled-system/jsx';

const buttonStyle = cva({
  base: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1.15,
    cursor: 'pointer',
    width: '100%',
    fontWeight: 600,
    _hover: {
      opacity: 0.7,
    },
    _disabled: {
      cursor: 'default',
      opacity: 0.4,
      pointerEvents: 'none',
    },
  },
  variants: {
    type: {
      pill: {
        color: 'primary',
        bg: 'tint',
        borderRadius: '100vh',
        _disabled: {
          bg: 'accent.100',
          opacity: 1,
        },
      },
      rounded: {
        color: 'primary',
        bg: 'tint',
        borderRadius: '4px',
      },
      outline: {
        color: 'accent_1000',
        bg: 'primary',
        border: '1px solid token(colors.accent.100)',
        borderRadius: '100vh',
      },
      whiteOverBlack: {
        color: 'white',
        bg: 'black',
        border: '1px solid token(colors.accent.100)',
        borderRadius: '100vh',
      },
      link: {
        color: 'tint',
      },
    },
    size: {
      small: { padding: '8px', fontSize: '12px', height: '26px' },
      default: {
        padding: '8px 14px',
        fontSize: '16px',
        height: { base: '60px', lg: '48px' },
      },
      medium: {
        fontSize: {
          lg: '14px',
          base: '12px',
        },
        padding: {
          lg: '9px 16px 9px 16px',
          base: '8px',
        },
        width: {
          lg: '90px',
          base: 'auto',
        },
      },
    },
  },
  defaultVariants: {
    type: 'pill',
    size: 'default',
  },
});

export type ButtonVariants = RecipeVariantProps<typeof buttonStyle>;

export const Button = styled('button', buttonStyle);
export const ButtonNextLink = styled(Link, buttonStyle);
