export const Genre = {
  GENRE_HORROR_THRILLER: 'ホラー・ミステリー',
  GENRE_ROMANCE: '恋愛・ロマンス',
  GENRE_BL: 'BL',
  GENRE_DRAMA: 'ドラマ',
  GENRE_FANTASY: 'ファンタジー・異世界・SF',
  GENRE_ROMANCE_FANTASY: 'ロマンスファンタジー',
  GENRE_COMEDY: 'コメディ',
  GENRE_FANFIC: '二次創作・夢小説',
  GENRE_OTHER: '雑談、その他',
} as const;
export type Genre = typeof Genre[keyof typeof Genre];

export type GenrePath = {
  genre: Genre;
  path: string;
};

export const GenrePaths = [
  {
    genre: Genre.GENRE_HORROR_THRILLER,
    path: 'horror',
  },
  {
    genre: Genre.GENRE_ROMANCE,
    path: 'romance',
  },
  {
    genre: Genre.GENRE_BL,
    path: 'bl',
  },
  {
    genre: Genre.GENRE_DRAMA,
    path: 'drama',
  },
  {
    genre: Genre.GENRE_FANTASY,
    path: 'fantasy',
  },
  {
    genre: Genre.GENRE_ROMANCE_FANTASY,
    path: 'fantasy-romance',
  },
  {
    genre: Genre.GENRE_COMEDY,
    path: 'comedy',
  },
];

export const genreFromPath = (genrePath: string): Genre => {
  switch (genrePath) {
    case 'horror':
      return Genre.GENRE_HORROR_THRILLER;
    case 'romance':
      return Genre.GENRE_ROMANCE;
    case 'bl':
      return Genre.GENRE_BL;
    case 'drama':
      return Genre.GENRE_DRAMA;
    case 'fantasy':
      return Genre.GENRE_FANTASY;
    case 'fantasy-romance':
      return Genre.GENRE_ROMANCE_FANTASY;
    case 'comedy':
      return Genre.GENRE_COMEDY;
    default:
      return Genre.GENRE_OTHER;
  }
};
